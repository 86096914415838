import React, { useEffect } from "react";
import "./ProjectsPage.css"; // Assurez-vous que le chemin vers votre fichier CSS est correct
import Project1Image from "../assets/img/informatixP/portable.png";
import Project2Image from "../assets/img/TikAddict/portable.png";
import BloomyImage from "../assets/icone/Bloomy.png"; // Ajoutez l'image de votre projet Bloomy
import Cosmic from "../assets/cosmic.webp"
import AOS from "aos";
import "aos/dist/aos.css";

const ProjectsPage = () => {
  const goToInformatix = () => {
    window.open("https://infoormatix.fr/", "_blank", "noopener,noreferrer"); // Ouvre le lien externe dans un nouvel onglet
  };

  const goToAddict = () => {
    window.open(
      "https://charlifeldschool.podia.com/",
      "_blank",
      "noopener,noreferrer"
    ); // Ouvre le lien externe dans un nouvel onglet
  };

  const goToBloomy = () => {
    window.location.href = "/bloomy"; // Redirige vers la page Bloomy sur votre site
  };
  const goToCosmic = () => {
    window.location.href = "/Cosmic"; // Redirige vers la page Bloomy sur votre site
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
    });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="projects-container">
      <h1>Projets réalisés</h1>
      <div className="project-grid">
        {/* Première carte */}
        <div className="project-card" data-aos="fade-up">
          <img src={Project1Image} alt="Projet 1" className="project-image" />
          <h2>Informatix</h2>
          <p>WebDesign - Vitrine - Création</p>
          <button className="discover-btn" onClick={goToInformatix}>
            Découvrir
          </button>
        </div>

        {/* Deuxième carte */}
        <div className="project-card" data-aos="fade-down">
          <img src={Project2Image} alt="Projet 2" className="project-image" />
          <h2>TikTok Addict</h2>
          <p>WebDesign - Vitrine - Création</p>
          <button className="discover-btn" onClick={goToAddict}>
            Découvrir
          </button>
        </div>

        {/* Troisième carte - Bloomy */}
        <div className="project-card" data-aos="fade-up">
          <img src={BloomyImage} alt="Bloomy" className="project-image" />
          <h2>Bloomy</h2>
          <p>Jeu Mobile - iOS - Publicité</p>
          <button className="discover-btn" onClick={goToBloomy}>
            Découvrir
          </button>
        </div>
        {/* Troisième carte - Bloomy */}
        <div className="project-card" data-aos="fade-up">
          <img src={Cosmic} alt="Bloomy" className="project-image" />
          <h2>Bloomy</h2>
          <p>Jeu Mobile - iOS - Publicité</p>
          <button className="discover-btn" onClick={goToCosmic}>
            Découvrir
          </button>
        </div>

        {/* Ajoutez d'autres cartes de projet ici */}
      </div>
    </div>
  );
};

export default ProjectsPage;
