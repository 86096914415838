import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Navbar from './Components/NavBar';
import ProjectsPage from './Pages/ProjectsPage';
import Contact from './Pages/ContactPage';
import ServicePage from './Pages/ServicePage';
import Informatix from './Pages/Informatix';
import Footer from './Components/Footer';
import LegalMentionsPage from './Pages/LegalMentionsPage';
import AddictPage from './Pages/TikTokAddict';
import Questionnaire from './Pages/Questionnaire';
import ThankYou from './Pages/Thankyou';
import Sidebar from './Pages/PagesCrm/Sidebar';
import Dashboard from './Pages/PagesCrm/Dashboard';
import Contacts from './Pages/PagesCrm/Contacts';
import Statistics from './Pages/PagesCrm/Statistics';
import Notebook from './Pages/PagesCrm/Notebook';
import ClientSpace from './Pages/PagesCrm/ClientSpace';
import ToDo from './Pages/PagesCrm/ToDo';
import Documents from './Pages/PagesCrm/Documents';
import ClientDashboard from './Pages/ClientDashboard';
import CrmProjectsPage from './Pages/PagesCrm/CrmProjectsPage';
import Bloomy from './Pages/Bloomy';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import CosmicRunner from './Pages/Cosmic';
import AdComponent from './Pages/AdComponent';
const AppContent = () => {
  const [clients, setClients] = useState(() => {
    const storedClients = localStorage.getItem('clients');
    return storedClients ? JSON.parse(storedClients) : [];
  });

  const [tasks, setTasks] = useState(() => {
    const storedTasks = localStorage.getItem('tasks');
    return storedTasks ? JSON.parse(storedTasks) : [];
  });

  const [projects] = useState([
    { id: 1, name: 'Projet A', status: 'ongoing', deadline: '2024-07-01', progress: 70, createdAt: '2024-01-01' },
    { id: 2, name: 'Projet B', status: 'completed', deadline: '2023-12-01', progress: 100, createdAt: '2023-05-01' },
    { id: 3, name: 'Projet C', status: 'ongoing', deadline: '2024-09-01', progress: 40, createdAt: '2024-03-01' },
  ]);

  useEffect(() => {
    localStorage.setItem('clients', JSON.stringify(clients));
  }, [clients]);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  const handleCreateClient = (clientData) => {
    const newClient = { ...clientData, createdAt: new Date().toISOString() };
    setClients([...clients, newClient]);
    console.log('Client created:', newClient);
  };

  const handleUpdateClient = (updatedClient) => {
    setClients(clients.map(client => client.clientId === updatedClient.clientId ? updatedClient : client));
  };

  const handleDeleteClient = (clientId) => {
    setClients(clients.filter(client => client.clientId !== clientId));
  };

  const handleCreateTask = (taskData) => {
    const newTask = { ...taskData, id: tasks.length + 1 };
    setTasks([...tasks, newTask]);
    console.log('Task created:', newTask);
  };

  console.log('Current clients:', clients);
  console.log('Current tasks:', tasks);

  const isCrmRoute = window.location.pathname.startsWith('/crm');

  return (
    <div className={`app ${isCrmRoute ? 'crm-app' : ''}`}>
      {!isCrmRoute && <Navbar />}
      <div className={`main-layout ${isCrmRoute ? 'crm-layout' : ''}`}>
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/projets" element={<ProjectsPage projects={projects} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/informatix" element={<Informatix />} />
          <Route path="/mentions-legales" element={<LegalMentionsPage />} />
          <Route path="/Addict" element={<AddictPage />} />
          <Route path="/Questionnaire" element={<Questionnaire />} />
          <Route path="/Bloomy" element={<Bloomy />} />
          <Route path="/Cosmic" element={<CosmicRunner />} />

          <Route path="/Privacy-policy" element={<PrivacyPolicy />} />


          <Route path="/thanks" element={<ThankYou />} />
          <Route path="/crm/*" element={
            <div className="crm-content">
              <Sidebar />
              <div className="crm-main-content">
                <Routes>
                  <Route path="" element={<Navigate to="dashboard" />} /> {/* Redirection par défaut */}
                  <Route path="dashboard" element={<Dashboard clients={clients} tasks={tasks} projects={projects} />} />
                  <Route path="statistics" element={<Statistics clients={clients} tasks={tasks} projects={projects} />} />
                  <Route path="notebook" element={<Notebook />} />
                  <Route path="client-space" element={<ClientSpace onCreateClient={handleCreateClient} clients={clients} onUpdateClient={handleUpdateClient} onDeleteClient={handleDeleteClient} />} />
                  <Route path="to-do/*" element={<ToDo tasks={tasks} setTasks={setTasks} onCreateTask={handleCreateTask} />} />
                  <Route path="documents/*" element={<Documents />} />
                  <Route path="contacts" element={<Contacts onCreateClient={handleCreateClient} />} />
                  <Route path="crm-projects/*" element={
                    <div>
                      <Routes>
                        <Route path="ongoing" element={<CrmProjectsPage projects={projects.filter(project => project.status === 'ongoing')} />} />
                        <Route path="completed" element={<CrmProjectsPage projects={projects.filter(project => project.status === 'completed')} />} />
                      </Routes>
                    </div>
                  } />
                </Routes>
              </div>
            </div>
          } />
          <Route path="/client/:clientId" element={<ClientDashboard clients={clients} />} /> {/* Route dynamique pour les espaces clients */}
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
