import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = ({ clients, tasks, projects }) => {
  const navigate = useNavigate();

  const now = new Date();
  const last30Days = new Date(now.setDate(now.getDate() - 30));
  const previous30Days = new Date(now.setDate(now.getDate() - 30));

  const countInRange = (items, start, end) => 
    items.filter(item => new Date(item.createdAt) >= start && new Date(item.createdAt) < end).length;

  const currentClients = countInRange(clients, last30Days, new Date());
  const previousClients = countInRange(clients, previous30Days, last30Days);

  const currentTasks = countInRange(tasks, last30Days, new Date());
  const previousTasks = countInRange(tasks, previous30Days, last30Days);

  const currentProjects = countInRange(projects, last30Days, new Date());
  const previousProjects = countInRange(projects, previous30Days, last30Days);

  const getTrend = (current, previous) => {
    const difference = current - previous;
    const percentage = previous === 0 ? (current === 0 ? 0 : 100) : (difference / previous) * 100;
    return {
      trend: difference > 0 ? '↑' : difference < 0 ? '↓' : '→',
      percentage: Math.abs(percentage.toFixed(2))
    };
  };

  const clientTrend = getTrend(currentClients, previousClients);
  const taskTrend = getTrend(currentTasks, previousTasks);
  const projectTrend = getTrend(currentProjects, previousProjects);

  const handleStatisticsClick = () => {
    navigate('/crm/statistics');
  };

  return (
    <div className="dashboard">
      <div className="card" onClick={() => navigate('/crm/client-space')}>
        <h3>Clients actifs</h3>
        <p>{clients.length}</p>
      </div>
      <div className="card" onClick={() => navigate('/crm/to-do')}>
        <h3>Tâches à faire</h3>
        <p>{tasks.filter(task => task.status === 'pending').length}</p>
      </div>
      <div className="card" onClick={() => navigate('/crm/to-do')}>
        <h3>Tâches en cours</h3>
        <p>{tasks.filter(task => task.status === 'in-progress').length}</p>
      </div>
      <div className="card" onClick={() => navigate('/crm/to-do')}>
        <h3>Tâches effectuées</h3>
        <p>{tasks.filter(task => task.status === 'completed').length}</p>
      </div>
      <div className="card statistics-card" onClick={handleStatisticsClick}>
        <h3>Statistiques</h3>
        <p>Clients: {clientTrend.trend} {clientTrend.percentage}%</p>
        <p>Tâches: {taskTrend.trend} {taskTrend.percentage}%</p>
        <p>Projets: {projectTrend.trend} {projectTrend.percentage}%</p>
      </div>
    </div>
  );
};

export default Dashboard;
