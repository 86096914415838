import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import "./Questionnaire.css";

function Questionnaire() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [messageStatus, setMessageStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const questions = [
    "Quel est votre nom et prénom ?",
    "Quel est votre adresse e-mail ?",
    "Quel est votre numéro de telephone ?",
    "Quel est le nom de votre entreprise ?",
    "Possedez vous actuellement un site internet ?",
    "Décrivez-vous moi comment vous voyez votre site ?",
    {
      question: "Souhaitez vous en discuter ?",
      choices: ["Par Teams", "Par Telephone", "Non merci", "Par Mail"],
    },
    {
      question: "Quelle est votre budget ?",
      choices: ["< 500€", "500€ - 2000€", "2200€ - 5000€"],
    },
  ];
  const [answers, setAnswers] = useState(Array(questions.length).fill(""));
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const validateInput = (input, type) => {
    if (type === "email") {
      return /^\S+@\S+\.\S+$/.test(input);
    } else if (type === "phone") {
      return /^\+?\d{10,}$/.test(input);
    }
    return true;
  };

  const handleNextQuestion = () => {
    const inputType =
      currentQuestion === 1 ? "email" : currentQuestion === 2 ? "phone" : null;
    const isValid = validateInput(answers[currentQuestion], inputType);

    if (!isValid) {
      setErrorMessage(
        inputType === "email"
          ? "Veuillez saisir un e-mail valide."
          : "Veuillez saisir un numéro de téléphone valide."
      );
      return;
    }

    setErrorMessage("");
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const templateParams = {
      from_name: answers[0],
      from_email: answers[1],
      phone: answers[2],
      company_name: answers[3],
      has_website: answers[4],
      site_description: answers[5],
      discussion_preference: answers[6], // Adapté pour la 7ème question
      budget: answers[7], // Adapté pour la 8ème question
    };

    emailjs
      .send(
        "Agence-Sunlight",
        "Agence-Sunlight-question",
        templateParams,
        "f9DSiGMsiech4bZhI"
      )
      .then(
        (result) => {
          console.log("Message envoyé", result.text);
          setMessageStatus(true);
          setProgress(0);

          let progressInterval = setInterval(() => {
            setProgress((oldProgress) => {
              const newProgress = oldProgress + 50; // Incrémente de 50% toutes les 1 seconde pour atteindre 100% en 2 secondes
              if (newProgress >= 100) {
                clearInterval(progressInterval);
                return 100;
              }
              return newProgress;
            });
          }, 1000); // Mise à jour toutes les 1 secondes

          setTimeout(() => {
            setMessageStatus(false);
            setProgress(0);
            setAnswers(Array(questions.length).fill(""));
            setCurrentQuestion(0);
          }, 2000);
        },
        (error) => {
          console.error("Erreur d'envoi", error.text);
        }
      );
  };

  const handleAnswerChange = (value) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = value;
    setAnswers(newAnswers);
  };

  return (
    <div className="questionnaire-container">
      <h1 className="questionnaire-title">Questionnaire</h1>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {messageStatus && (
        <div className="message-container" style={{ display: "block" }}>
          Message envoyé
          <span className="close-btn" onClick={() => setMessageStatus(false)}>
            ×
          </span>
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}
      <div className="questionnaire-content">
        <form className="questionnaire-form" onSubmit={handleSubmit}>
          <div className="question-container">
            <label className="question-label">
              {typeof questions[currentQuestion] === "object"
                ? questions[currentQuestion].question
                : questions[currentQuestion]}
            </label>
            {typeof questions[currentQuestion] === "object" ? (
              <select
                className="form-input"
                value={answers[currentQuestion]}
                onChange={(e) => handleAnswerChange(e.target.value)}
                required
              >
                <option value="">Sélectionnez une option</option>
                {questions[currentQuestion].choices.map((choice, index) => (
                  <option key={index} value={choice}>
                    {choice}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                name={`question${currentQuestion + 1}`}
                placeholder={"Votre réponse"}
                className="form-input"
                value={answers[currentQuestion]}
                onChange={(e) => handleAnswerChange(e.target.value)}
                required
              />
            )}
          </div>
          <div className="button-container">
            <button
              type="button"
              className="questionnaire-button"
              onClick={handleNextQuestion}
            >
              {currentQuestion === questions.length - 1 ? "Envoyer" : "Suivant"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Questionnaire;
