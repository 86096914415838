import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Bloomy.css";
import GameImage from "../assets/img/bloomy.png"; // Image représentative de Bloomy
import AppStoreIcon from "../assets/img/appstore.png";

function Bloomy() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="game-container">
      <div className="game-header" data-aos="fade-down">
        <h1 className="game-title">Bloomy</h1>
        <p className="game-tagline">Fusionnez les fruits et atteignez le sommet du plaisir !</p>
      </div>

      <div className="game-content">
        <div className="game-image" data-aos="fade-right">
          <img src={GameImage} alt="Bloomy" />
        </div>
        <div className="game-description" data-aos="fade-left">
          <h2>Aperçu du Jeu</h2>
          <p>
            Plongez dans l'univers coloré de Bloomy, un jeu captivant pour tous les âges. 
            Fusionnez les fruits, débloquez des niveaux, et découvrez des surprises à chaque étape.
            Simple à jouer, mais difficile à maîtriser, Bloomy est le jeu parfait pour vous divertir 
            partout et à tout moment.
          </p>
          <div className="download-buttons">
            <a href="#" className="store-link">
              <img src={AppStoreIcon} alt="Télécharger sur App Store" />
            </a>
          </div>
        </div>
      </div>

      <div className="game-features">
        <h2 className="section-title" data-aos="fade-up">Caractéristiques du Jeu</h2>
        <div className="features-list" data-aos="fade-up">
          <div className="feature-card">
            <h3>Graphismes Colorés</h3>
            <p>Des visuels éclatants qui donnent vie à chaque fruit.</p>
          </div>
          <div className="feature-card">
            <h3>Gameplay Addictif</h3>
            <p>Fusionnez des fruits identiques pour atteindre le niveau ultime.</p>
          </div>
          <div className="feature-card">
            <h3>Mises à Jour Régulières</h3>
            <p>Nouveaux niveaux et défis ajoutés régulièrement pour toujours plus de fun.</p>
          </div>
        </div>
      </div>

      <div className="faq-section" data-aos="fade-up">
        <h2 className="section-title">FAQ</h2>
        <div className="faq-item">
          <h3>Comment jouer à Bloomy ?</h3>
          <p>Il vous suffit de faire glisser les fruits identiques les uns sur les autres pour les fusionner. 
          Le but est de continuer à fusionner jusqu'à ce que vous atteigniez le fruit ultime.</p>
        </div>
        <div className="faq-item">
          <h3>Est-ce que Bloomy est gratuit ?</h3>
          <p>Oui, Bloomy est totalement gratuit à télécharger et à jouer. Il propose des achats intégrés pour ceux 
          qui souhaitent progresser plus rapidement.</p>
        </div>
        <div className="faq-item">
          <h3>Sur quelles plateformes puis-je jouer à Bloomy ?</h3>
          <p>Bloomy est actuellement disponible uniquement sur iOS via l'App Store.</p>
        </div>
      </div>

      <div className="privacy-section" data-aos="fade-up">
        <h2 className="section-title">Politique de Confidentialité</h2>
        <p>
          Chez **Bloomy**, nous respectons votre vie privée. Nous ne collectons pas d'informations personnelles sans votre consentement. 
          Toute donnée collectée par l'application est utilisée uniquement pour améliorer votre expérience de jeu et n'est jamais partagée 
          avec des tiers sans votre accord explicite.
        </p>
        <p>
          Pour en savoir plus sur notre politique de confidentialité, vous pouvez consulter notre 
          <a href="/privacy-policy" className="privacy-link">page dédiée</a>.
        </p>
      </div>

      <div className="contact-section" data-aos="fade-up">
        <h2 className="section-title">Contactez-nous</h2>
        <p>
          Vous avez des questions ou des suggestions ? Nous serions ravis de vous entendre. 
          Cliquez sur le bouton ci-dessous pour accéder à notre formulaire de contact.
        </p>
        <a href="/contact">
          <button className="contact-button">Contactez-nous</button>
        </a>
      </div>

      <div className="game-footer" data-aos="fade-up">
        <p className="call-to-action">
          Ne manquez pas l'occasion de jouer à Bloomy ! Téléchargez-le dès aujourd'hui sur l'App Store.
        </p>
      </div>
    </div>
  );
}

export default Bloomy;
