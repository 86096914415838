import React, { useState, useEffect } from "react";
import "./ServicesPage.css";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const ServicesPage = () => {
  let navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);

  const goToWebDesign = () => {
    navigate("/Questionnaire");
  };

  const goToSeo = () => {
    navigate("/Questionnaire");
  };

  const goToMaintenance = () => {
    navigate("/Questionnaire");
  };
  const gotoModif = () => {
    navigate("/Questionnaire");
  };
  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
    });
  }, []);
  return (
    <div className="services-container">
      <h1>Nos Services</h1>
      <div className="service-grid" data-aos="fade-up">
        <div
          className={`service-card  ${
            hoveredCard && hoveredCard !== "standard" ? "dimmed" : ""
          }`}
          onMouseEnter={() => setHoveredCard("standard")}
          onMouseLeave={() => setHoveredCard(null)}
        >
          <h2>Standard</h2>
          <p className="description">
            Commencez avec style grâce notre plan abordable, design et surtout
            élégant
          </p>
          <hr className="service-divider" />
          <p className="offres">- Création d'un site | Vitrine |</p>
          <p className="offres">
            - Création d'un site avec 1 à 4 pages professionnelles
          </p>
          <p className="offres">- Optimisations SEO | référencement naturel</p>
          <p className="offres">
            - Maintenance technique offerte le premier mois
          </p>
          <p className="description">
            625€ / <span className="month-info">1 fois</span>
          </p>
          <button className="discover-btn" onClick={goToWebDesign}>
            Découvrir
          </button>
        </div>

        <div
          className={`service-card ${
            hoveredCard && hoveredCard !== "premium" ? "dimmed" : ""
          }`}
          onMouseEnter={() => setHoveredCard("premium")}
          onMouseLeave={() => setHoveredCard(null)}
        >
          <h2>Premium</h2>
          <p className="description">
            Améliorez encore la qualité visuelle de votre site, avec notre
            formule premium
          </p>
          <hr className="service-divider" />
          <p className="offres">- Création d'un site | Vitrine |</p>
          <p className="offres">
            - Création d'un site avec 1 à 7 pages professionnelles
          </p>
          <p className="offres">- Optimisations SEO | référencement naturel</p>
          <p className="offres">
            - Maintenance technique offerte le premier mois
          </p>
          <p className="offres">- Hébergement offert le premier mois</p>
          <p className="offres">- 4 retouches de site offertes / mois</p>
          <p className="description">
            998€ / <span className="month-info">1 fois</span>
          </p>
          <button className="discover-btn" onClick={goToSeo}>
            Découvrir
          </button>
        </div>

        <div
          className={`service-card ${
            hoveredCard && hoveredCard !== "vip" ? "dimmed" : ""
          }`}
          onMouseEnter={() => setHoveredCard("vip")}
          onMouseLeave={() => setHoveredCard(null)}
        >
          <h2>VIP</h2>
          <p className="description">
            Atteignez l'excellence visuelle de votre site, avec notre formule
            VIP
          </p>
          <hr className="service-divider" />
          <p className="offres">
            - Création d'un site | Vitrine | E-Commerce |
          </p>
          <p className="offres">- Création de 7 pages à ∞</p>
          <p className="offres">- Optimisations SEO | référencement naturel</p>
          <p className="offres">
            - Maintenance technique offerte le premier mois
          </p>
          <p className="offres">- Hébergement offert le premier mois</p>
          <p className="offres">- 7 retouches de site offertes / mois</p>
          <p className="offres">- Toute autre demande</p>
          <p className="description">
            1297€ / <span className="month-info">1 fois</span>
          </p>
          <button className="discover-btn" onClick={goToMaintenance}>
            Découvrir
          </button>
        </div>

        <div
          className={`service-card ${
            hoveredCard && hoveredCard !== "modif" ? "dimmed" : ""
          }`}
          onMouseEnter={() => setHoveredCard("modif")}
          onMouseLeave={() => setHoveredCard(null)}
        >
          <h2>Modifications site Web</h2>
          <p className="description">
            Vous avez déjà un site ? Vous avez besoin juste d'un morceau de code
            ? Contactez-moi.
          </p>
          <hr className="service-divider" />
          <p className="offres">
            - Création d'un site | Vitrine | E-Commerce |
          </p>
          <p className="offres">- Création de pages ou de modules</p>
          <p className="offres">- Optimisations SEO | référencement naturel</p>
          <p className="offres">- Toute demande</p>
          <p className="description">
            100€ - 500€ / <span className="month-info">1fois</span>
          </p>
          <button className="discover-btn" onClick={gotoModif}>
            Découvrir
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
