import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import "./ContactPage.css";

function ContactPage() {
  const [messageStatus, setMessageStatus] = useState(false);
  const [progress, setProgress] = useState(0);
  const [name, setName] = useState(""); // État pour le nom
  const [email, setEmail] = useState(""); // État pour l'email
  const [message, setMessage] = useState(""); // État pour le message

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "Agence-Sunlight",
        "Agence-Sunlight-mail",
        e.target,
        "f9DSiGMsiech4bZhI"
      )
      .then(
        (result) => {
          console.log("Message envoyé", result.text);
          setMessageStatus(true); // Affiche le message
          let progressInterval = setInterval(() => {
            setProgress((oldProgress) => {
              if (oldProgress === 100) {
                clearInterval(progressInterval);
                return 100;
              }
              return Math.min(oldProgress + 10, 100);
            });
          }, 300); // Accélère la progression pour correspondre au temps de 5 secondes
          setTimeout(() => {
            setMessageStatus(false); // Ferme le message après 5 secondes
            setProgress(0); // Réinitialise la barre de progression
            // Réinitialiser les champs du formulaire
            setName("");
            setEmail("");
            setMessage("");
          }, 3000);
        },
        (error) => {
          console.error("Erreur d'envoi", error.text);
        }
      );
  };

  return (
    <div className="contact-container">
      {messageStatus && (
        <div className="message-container" style={{ display: "block" }}>
          Message envoyé
          <span className="close-btn" onClick={() => setMessageStatus(false)}>
            ×
          </span>
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}
      <div className="contact-content">
        <h1 className="contact-titre">Contactez-nous</h1>
        <p className="contact-sous-titre">
          Nous sommes là pour répondre à toutes vos questions
        </p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="from_name"
            placeholder="Votre nom"
            className="form-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            name="from_email"
            placeholder="Votre email"
            className="form-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <textarea
            name="message"
            placeholder="Votre message"
            className="form-textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
          <button type="submit" className="contact-bouton">
            Envoyer
          </button>
        </form>
        <div className="contact-info">
        <div className="contact-item">
            <i className="fas fa-envelope contact-icon"></i>
            <span className="contact-text">Contact@Agence-sunlight.fr</span>
          </div>
          <div className="contact-item">
            <i className="fas fa-phone contact-icon"></i>
            <span className="contact-text">+33 06 10 64 59 00</span>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
