import React from 'react';
import { useParams } from 'react-router-dom';
import './ClientDashboard.css';

const ClientDashboard = ({ clients }) => {
  const { clientId } = useParams();
  const client = clients.find(client => client.id === parseInt(clientId, 10));

  console.log('Client ID from URL:', clientId);
  console.log('Clients:', clients);
  console.log('Client data:', client);

  if (!client) {
    return <div className="client-dashboard">Client introuvable</div>;
  }

  const maintenanceDuration = '1 an'; // Exemple de durée de contrat de maintenance
  const progressPercentage = client.progress; // Exemple de progression

  return (
    <div className="client-dashboard">
      <h1>Bienvenue {client.firstName} {client.lastName}</h1>
      <p>Date d'échéance du projet : {client.deadline}</p>
      <p>Durée du contrat de maintenance : {maintenanceDuration}</p>
      <div className="progress-container">
        <h3>Progression du projet :</h3>
        <div className="progress-bar">
          <div
            className="progress"
            style={{ width: `${progressPercentage}%` }}
          >
            {progressPercentage}%
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
