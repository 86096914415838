import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import InstagramIcon from "../assets/img/instagram-icon.png"; // Assurez-vous que le chemin est correct
import LinkedinIcon from "../assets/img/linkedin-icon.png"; // Assurez-vous que le chemin est correct

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-left">
          <a href="https://www.instagram.com/loic_lrg/">
            <img
              src={InstagramIcon}
              alt="Instagram-logo"
              className="social-icon"
            />
          </a>
          <a href="https://www.linkedin.com/company/101803035/admin/feed/posts/?feedType=following">
            <img
              src={LinkedinIcon}
              alt="LinkedIn-logo"
              className="social-icon"
            />
          </a>
        </div>

        <div className="footer-phone">
          <p>Tél : 06 10 64 59 00</p>
        </div>

        <div className="footer-center">
          <h3>Agence Sunlight</h3>
          <Link to="/mentions-legales" className="legal-colors">
            Mentions légales
          </Link>
          <p>© Agence Sunlight | SIRET : 983 185 315 00014</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
