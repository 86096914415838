import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Contacts.css';

const Contacts = ({ onCreateClient }) => {
  const [contacts, setContacts] = useState(() => {
    const storedContacts = localStorage.getItem('contacts');
    return storedContacts ? JSON.parse(storedContacts) : [];
  });
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [company, setCompany] = useState('');
  const [category, setCategory] = useState('client');
  const [hasDocuments, setHasDocuments] = useState('no');
  const [documents, setDocuments] = useState([]);
  const [filter, setFilter] = useState('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [clientId, setClientId] = useState('');

  useEffect(() => {
    localStorage.setItem('contacts', JSON.stringify(contacts));
  }, [contacts]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setName('');
    setFirstName('');
    setEmail('');
    setPhone('');
    setAddress('');
    setCompany('');
    setDocuments([]);
    setHasDocuments('no');
    setCategory('client');
    setClientId('');
  };

  const handleAddOrUpdateContact = () => {
    if (name.trim() && firstName.trim() && email.trim() && phone.trim() && address.trim() && company.trim() && clientId.trim()) {
      const newContact = { id: clientId, name, firstName, email, phone, address, company, documents, category, clientId };
      setContacts([...contacts, newContact]);
      handleCloseModal();
      if (category === 'client') {
        console.log('Creating client:', newContact);
        onCreateClient(newContact);
      }
      if (hasDocuments === 'yes') {
        createDocumentFolders(newContact);
      }
    } else {
      alert("Tous les champs obligatoires doivent être remplis");
    }
  };

  const handleEditContact = (contact) => {
    setSelectedContact(contact);
    setName(contact.name);
    setFirstName(contact.firstName);
    setEmail(contact.email);
    setPhone(contact.phone);
    setAddress(contact.address);
    setCompany(contact.company);
    setCategory(contact.category);
    setHasDocuments(contact.documents.length > 0 ? 'yes' : 'no');
    setDocuments(contact.documents);
    setClientId(contact.clientId);
    setIsEditContactModalOpen(true);
  };

  const handleUpdateContact = () => {
    if (name.trim() && firstName.trim() && email.trim() && phone.trim() && address.trim() && company.trim() && clientId.trim()) {
      setContacts(contacts.map(contact =>
        contact.id === selectedContact.id
          ? { ...contact, name, firstName, email, phone, address, company, documents, category, clientId }
          : contact
      ));
      setIsEditContactModalOpen(false);
      handleCloseModal();
    } else {
      alert("Tous les champs obligatoires doivent être remplis");
    }
  };

  const handleViewContact = (contact) => {
    setSelectedContact(contact);
    setIsViewModalOpen(true);
  };

  const handleCloseViewModal = () => {
    setIsViewModalOpen(false);
    setSelectedContact(null);
  };

  const handleDocumentChange = (e) => {
    const files = Array.from(e.target.files).map(file => ({
      name: file.name,
      url: URL.createObjectURL(file)
    }));
    setDocuments(files);
  };

  const handleDeleteContact = (id) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce contact définitivement ?')) {
      setContacts(contacts.filter(contact => contact.id !== id));
    }
  };

  const createDocumentFolders = (contact) => {
    const savedDocuments = localStorage.getItem('documents');
    const documents = savedDocuments ? JSON.parse(savedDocuments) : {
      'clients': [],
      'prospects': [],
      'comptabilite-interne': []
    };

    const folderName = `${contact.firstName} ${contact.name}`;

    if (contact.category === 'client') {
      if (!documents.clients.some(folder => folder.name === folderName)) {
        documents.clients.push({ name: folderName, files: contact.documents });
      }
    } else if (contact.category === 'prospect') {
      if (!documents.prospects.some(folder => folder.name === folderName)) {
        documents.prospects.push({ name: folderName, files: contact.documents });
      }
    }

    localStorage.setItem('documents', JSON.stringify(documents));
  };

  const filteredContacts = filter === 'all' ? contacts : contacts.filter(contact => contact.category === filter);

  return (
    <div className="contacts-page">
      <div className="header">
        <h1>Contacts</h1>
        <div className="header-buttons">
          <button className="add-button" onClick={handleOpenModal}>Ajouter un contact</button>
          <button className="edit-button" onClick={() => setIsEditMode(!isEditMode)}>
            <i className="fas fa-pencil-alt"></i>
          </button>
        </div>
      </div>
      <div className="filter-container">
        <h2>Filtrer les contacts</h2>
        <select value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="all">Tous</option>
          <option value="client">Clients</option>
          <option value="prospect">Prospects</option>
        </select>
      </div>
      <div className="contacts-list">
        {filteredContacts.length === 0 ? (
          <p className="no-contacts">Aucun contact trouvé</p>
        ) : (
          filteredContacts.map((contact) => (
            <div key={contact.id} className="contact-card">
              <h3>{contact.firstName} {contact.name}</h3>
              <p>{contact.email}</p>
              <p>{contact.category === 'client' ? 'Client' : 'Prospect'}</p>
              <button className="view-button" onClick={() => handleViewContact(contact)}>Voir &gt;</button>
              {isEditMode && (
                <button className="delete-button" onClick={() => handleDeleteContact(contact.id)}>
                  ×
                </button>
              )}
            </div>
          ))
        )}
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Ajouter un contact</h2>
            <input
              type="text"
              placeholder="Prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Nom"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Téléphone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              type="text"
              placeholder="Adresse"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <input
              type="text"
              placeholder="Entreprise"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            <input
              type="text"
              placeholder="Identifiant Client (ex: kiyoutou)"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
            />
            <select value={hasDocuments} onChange={(e) => setHasDocuments(e.target.value)}>
              <option value="no">Documents: Non</option>
              <option value="yes">Documents: Oui</option>
            </select>
            {hasDocuments === 'yes' && (
              <input
                type="file"
                multiple
                onChange={handleDocumentChange}
              />
            )}
            <select value={category} onChange={(e) => setCategory(e.target.value)}>
              <option value="client">Client</option>
              <option value="prospect">Prospect</option>
            </select>
            <div className="modal-buttons">
              <button onClick={handleAddOrUpdateContact}>Ajouter</button>
              <button onClick={handleCloseModal}>Annuler</button>
            </div>
          </div>
        </div>
      )}

      {isViewModalOpen && selectedContact && (
        <div className="modal-overlay">
          <div className="modal view-modal">
            <button className="close-button" onClick={handleCloseViewModal}>×</button>
            <div className="contact-card-modal">
              <h3>{selectedContact.firstName} {selectedContact.name}</h3>
              <p><strong>Email :</strong> {selectedContact.email}</p>
              <p><strong>Téléphone :</strong> {selectedContact.phone}</p>
              <p><strong>Adresse :</strong> {selectedContact.address}</p>
              <p><strong>Entreprise :</strong> {selectedContact.company}</p>
              {selectedContact.documents && selectedContact.documents.length > 0 && (
                <>
                  <p><strong>Documents :</strong></p>
                  <ul>
                    {selectedContact.documents.map((doc, index) => (
                      <li key={index}>{doc.name}</li>
                    ))}
                  </ul>
                  <Link to={`/crm/documents/${selectedContact.category}`} className="documents-link">Voir documents</Link>
                </>
              )}
              <p><strong>Catégorie :</strong> {selectedContact.category === 'client' ? 'Client' : 'Prospect'}</p>
              <button className="edit-contact-button" onClick={() => handleEditContact(selectedContact)}>Modifier</button>
            </div>
          </div>
        </div>
      )}

      {isEditContactModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Modifier le contact</h2>
            <input
              type="text"
              placeholder="Prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Nom"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Téléphone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              type="text"
              placeholder="Adresse"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <input
              type="text"
              placeholder="Entreprise"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            <input
              type="text"
              placeholder="Identifiant Client (ex: kiyoutou)"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
            />
            <select value={hasDocuments} onChange={(e) => setHasDocuments(e.target.value)}>
              <option value="no">Documents: Non</option>
              <option value="yes">Documents: Oui</option>
            </select>
            {hasDocuments === 'yes' && (
              <input
                type="file"
                multiple
                onChange={handleDocumentChange}
              />
            )}
            <select value={category} onChange={(e) => setCategory(e.target.value)}>
              <option value="client">Client</option>
              <option value="prospect">Prospect</option>
            </select>
            <div className="modal-buttons">
              <button onClick={handleUpdateContact}>Mettre à jour</button>
              <button onClick={() => setIsEditContactModalOpen(false)}>Annuler</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contacts;
