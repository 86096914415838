import React, { useState } from 'react';
import './ClientSpace.css';

const ClientSpace = ({ onCreateClient, clients, onUpdateClient, onDeleteClient }) => {
  const [clientData, setClientData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    deadline: '',
    progress: 0,
    clientId: ''
  });
  const [showForm, setShowForm] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClientData({ ...clientData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      onUpdateClient(clientData);
    } else {
      onCreateClient(clientData);
    }
    setClientData({
      firstName: '',
      lastName: '',
      email: '',
      deadline: '',
      progress: 0,
      clientId: ''
    });
    setShowForm(false);
    setIsEditMode(false);
  };

  const handleEdit = (client) => {
    setClientData(client);
    setShowForm(true);
    setIsEditMode(true);
  };

  const handleDelete = (clientId) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce client?')) {
      onDeleteClient(clientId);
    }
  };

  return (
    <div className="client-space">
      <button onClick={() => setShowForm(!showForm)} className="toggle-form-button">
        {showForm ? 'Annuler' : 'Créer un espace client'}
      </button>
      {showForm && (
        <form onSubmit={handleSubmit} className="client-form">
          <div className="form-group">
            <label>Prénom:</label>
            <input
              type="text"
              name="firstName"
              value={clientData.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Nom:</label>
            <input
              type="text"
              name="lastName"
              value={clientData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={clientData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Échéance du projet:</label>
            <input
              type="date"
              name="deadline"
              value={clientData.deadline}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Identifiant Client (ex: kiyoutou):</label>
            <input
              type="text"
              name="clientId"
              value={clientData.clientId}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className="submit-button">
            {isEditMode ? 'Mettre à jour' : 'Créer l\'espace client'}
          </button>
        </form>
      )}
      <table className="client-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Échéance</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client.clientId}>
              <td>{client.clientId}</td>
              <td>{client.firstName}</td>
              <td>{client.lastName}</td>
              <td>{client.email}</td>
              <td>{client.deadline}</td>
              <td>
                <button onClick={() => handleEdit(client)}>Modifier</button>
                <button onClick={() => handleDelete(client.clientId)}>Supprimer</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientSpace;
