import React from 'react';
import { Bar, Pie, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { registerables } from 'chart.js';
import { Chart } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import './Statistics.css';

Chart.register(...registerables);

const Statistics = ({ clients, tasks, projects }) => {
  // Calcul des clients actifs par mois
  const clientsByMonth = new Array(12).fill(0);
  clients.forEach(client => {
    const month = new Date(client.createdAt).getMonth();
    clientsByMonth[month] += 1;
  });

  const clientsByMonthData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: 'Clients Actifs',
        data: clientsByMonth,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  const tasksByStatus = {
    labels: ['Pending', 'In Progress', 'Completed'],
    datasets: [
      {
        label: 'Tâches',
        data: [
          tasks.filter(task => task.status === 'pending').length,
          tasks.filter(task => task.status === 'in-progress').length,
          tasks.filter(task => task.status === 'completed').length,
        ],
        backgroundColor: ['rgba(255,99,132,0.4)', 'rgba(54,162,235,0.4)', 'rgba(75,192,192,0.4)'],
        borderColor: ['rgba(255,99,132,1)', 'rgba(54,162,235,1)', 'rgba(75,192,192,1)'],
        borderWidth: 1,
      },
    ],
  };

  const projectsByStatus = {
    labels: ['Ongoing', 'Completed'],
    datasets: [
      {
        label: 'Projets',
        data: [
          projects.filter(project => project.status === 'ongoing').length,
          projects.filter(project => project.status === 'completed').length,
        ],
        backgroundColor: ['rgba(153,102,255,0.4)', 'rgba(255,159,64,0.4)'],
        borderColor: ['rgba(153,102,255,1)', 'rgba(255,159,64,1)'],
        borderWidth: 1,
      },
    ],
  };

  const projectDeadlines = {
    labels: projects.map(project => new Date(project.deadline)),
    datasets: [
      {
        label: 'Échéances des projets',
        data: projects.map(project => ({ x: new Date(project.deadline), y: 1 })),
        backgroundColor: 'rgba(255,206,86,0.4)',
        borderColor: 'rgba(255,206,86,1)',
        borderWidth: 1,
        parsing: {
          xAxisKey: 'x',
          yAxisKey: 'y'
        },
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
  };

  const dateChartOptions = {
    ...chartOptions,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            month: 'MMM yyyy'
          },
          tooltipFormat: 'PP',
          adapters: {
            date: {
              locale: enUS,
            }
          }
        },
        title: {
          display: true,
          text: 'Date'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Projets'
        },
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  return (
    <div className="statistics">
      <h2>Statistiques</h2>
      <div className="chart-container small" style={{ height: '200px' }}>
        <h3>Clients Actifs par Mois</h3>
        <Bar data={clientsByMonthData} options={chartOptions} />
      </div>
      <div className="chart-container" style={{ height: '250px' }}>
        <h3>Tâches par Statut</h3>
        <Pie data={tasksByStatus} options={chartOptions} />
      </div>
      <div className="chart-container small" style={{ height: '200px' }}>
        <h3>Projets en cours et terminés</h3>
        <Bar data={projectsByStatus} options={chartOptions} />
      </div>
      <div className="chart-container" style={{ height: '250px' }}>
        <h3>Échéances des Projets à venir</h3>
        <Line data={projectDeadlines} options={dateChartOptions} />
      </div>
    </div>
  );
};

export default Statistics;
