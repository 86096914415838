import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Cosmic.css";
import GameImage from "../assets/game.png"; // Image représentative de Cosmic Runner
import AppStoreIcon from "../assets/img/appstore.png";

function CosmicRunner() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="game-container">
      <div className="game-header" data-aos="fade-down">
        <h1 className="game-title">Cosmic Runner</h1>
        <p className="game-tagline">Évitez les obstacles et courez vers les étoiles !</p>
      </div>

      <div className="game-content">
        <div className="game-image" data-aos="fade-right">
          <img src={GameImage} alt="Cosmic Runner" />
        </div>
        <div className="game-description" data-aos="fade-left">
          <h2>Aperçu du Jeu</h2>
          <p>
            Plongez dans l'univers intergalactique de Cosmic Runner, où votre mission est de naviguer 
            à travers des champs d'astéroïdes et d'obstacles stellaires. Avec une jouabilité simple mais 
            exigeante, ce jeu vous tiendra en haleine alors que vous essayez d'éviter les obstacles et 
            de courir aussi loin que possible. Préparez-vous pour une aventure spatiale palpitante !
          </p>
          <div className="download-buttons">
            <a href="#" className="store-link">
              <img src={AppStoreIcon} alt="Télécharger sur App Store" />
            </a>
          </div>
        </div>
      </div>

      <div className="game-features">
        <h2 className="section-title" data-aos="fade-up">Caractéristiques du Jeu</h2>
        <div className="features-list" data-aos="fade-up">
          <div className="feature-card">
            <h3>Graphismes Spatiaux</h3>
            <p>Des visuels époustouflants qui vous plongent dans l'espace.</p>
          </div>
          <div className="feature-card">
            <h3>Gameplay Intense</h3>
            <p>Évitez les obstacles dans un environnement dynamique et toujours changeant.</p>
          </div>
          <div className="feature-card">
            <h3>Défis Évolutifs</h3>
            <p>De nouveaux obstacles et défis apparaissent à chaque partie pour tester vos compétences.</p>
          </div>
        </div>
      </div>

      <div className="faq-section" data-aos="fade-up">
        <h2 className="section-title">FAQ</h2>
        <div className="faq-item">
          <h3>Comment jouer à Cosmic Runner ?</h3>
          <p>Utilisez les commandes de mouvement pour éviter les obstacles qui apparaissent devant vous. Le but est de survivre le plus longtemps possible tout en évitant les collisions.</p>
        </div>
        <div className="faq-item">
          <h3>Cosmic Runner est-il gratuit ?</h3>
          <p>Oui, Cosmic Runner est entièrement gratuit à télécharger et à jouer, avec des achats intégrés pour personnaliser votre expérience de jeu.</p>
        </div>
        <div className="faq-item">
          <h3>Sur quelles plateformes puis-je jouer à Cosmic Runner ?</h3>
          <p>Cosmic Runner est actuellement disponible sur iOS via l'App Store.</p>
        </div>
      </div>

      <div className="privacy-section" data-aos="fade-up">
        <h2 className="section-title">Politique de Confidentialité</h2>
        <p>
          Chez **Cosmic Runner**, nous respectons votre vie privée. Nous ne collectons pas d'informations personnelles sans votre consentement. 
          Toute donnée collectée par l'application est utilisée uniquement pour améliorer votre expérience de jeu et n'est jamais partagée 
          avec des tiers sans votre accord explicite.
        </p>
        <p>
          Pour en savoir plus sur notre politique de confidentialité, vous pouvez consulter notre 
          <a href="/privacy-policy" className="privacy-link">page dédiée</a>.
        </p>
      </div>

      <div className="contact-section" data-aos="fade-up">
        <h2 className="section-title">Contactez-nous</h2>
        <p>
          Vous avez des questions ou des suggestions ? Nous serions ravis de vous entendre. 
          Cliquez sur le bouton ci-dessous pour accéder à notre formulaire de contact.
        </p>
        <a href="/contact">
          <button className="contact-button">Contactez-nous</button>
        </a>
      </div>

      <div className="game-footer" data-aos="fade-up">
        <p className="call-to-action">
          Ne manquez pas l'occasion de jouer à Cosmic Runner ! Téléchargez-le dès aujourd'hui sur l'App Store.
        </p>
      </div>
    </div>
  );
}

export default CosmicRunner;
