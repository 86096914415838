import React from "react";
import "./TikTokAddict.css";
import Image1 from "../assets/img/TikAddict/screen1.PNG";
import Image2 from "../assets/img/TikAddict/screen2.PNG";

const AddictPage = () => {
  return (
    <div className="informatix-container">
      <h1 className="informatix-title">Informatix</h1>
      <div className="content">
        <img src={Image1} alt="acceuil site" className="main-image" />
        <p className="lorem-text">
          Découvrez notre{" "}
          <span className="highlight">plateforme de formation innovante</span>{" "}
          sur la page d'accueil, conçue pour offrir une{" "}
          <span className="highlight">
            expérience utilisateur intuitive et rapide
          </span>
          . Avec son interface épurée et ses visuels accrocheurs, notre page
          d'accueil guide les utilisateurs vers nos programmes et cours de
          manière efficace. Elle met en avant la{" "}
          <span className="highlight">
            facilité d'accès aux ressources éducatives
          </span>
          , tout en présentant une video introductive.
        </p>
      </div>
      <div className="content">
        <img src={Image2} alt="sommaire cours" className="main-image" />
        <p className="lorem-text">
          La page de sommaire de notre site offre une{" "}
          <span className="highlight">vue d'ensemble structurée</span> des video
          de la formation, facilitant aux apprenants la navigation et l'approche
          des modules. Chaque programme est clairement répertorié avec
          <span className="highlight"> des aperçus détaillés</span>, permettant
          une compréhension rapide des sujets et{" "}
          <span className="highlight">objectifs d'apprentissage</span>
        </p>
      </div>
    </div>
  );
};

export default AddictPage;
