// src/Pages/PagesCrm/Notebook.js
import React from "react";

const Notebook = () => {
  return (
    <div>
      <h1>Notebook</h1>
      <p>Keep your notes and ideas here.</p>
    </div>
  );
};

export default Notebook;
