import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import './NavBar.css';
import logoLeft from '../assets/img/logoSunlight.png';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const scrollToServicesSection = () => {
    scroller.scrollTo('services-section', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: +450, // Ajustez cette valeur pour descendre plus bas
    });
  };

  const handleServicesClick = (e) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollToServices: true } });
    } else {
      scrollToServicesSection();
    }
  };

  const handleAccueilClick = (e) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollToTop: true } });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (location.state && location.state.scrollToServices) {
      scrollToServicesSection();
      navigate(location.pathname, { replace: true, state: {} });
    }
    if (location.state && location.state.scrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  return (
    <nav className="navbar">
      <div className="navbar-header">
        <RouterLink to="/" className="navbar-logo" onClick={handleAccueilClick}>
          <img src={logoLeft} alt="Logo Gauche" className="navbar-logo-left" />
          <span className="logo-text">SunLight</span>
        </RouterLink>
        <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
          <div className={`line ${menuOpen ? 'open' : ''}`}></div>
          <div className={`line ${menuOpen ? 'open' : ''}`}></div>
          <div className={`line ${menuOpen ? 'open' : ''}`}></div>
        </div>
      </div>
      <ul className={`navbar-nav ${menuOpen ? 'open' : ''}`}>
        <li>
          <RouterLink to="/" className="nav-link" onClick={handleAccueilClick}>
            Accueil
          </RouterLink>
        </li>
        <li>
          <RouterLink to="/" className="nav-link" onClick={handleServicesClick}>
            Services
          </RouterLink>
        </li>
        <li>
          <RouterLink to="/projets" className="nav-link">
            Projets
          </RouterLink>
        </li>
        <li>
          <RouterLink to="/contact" className="nav-link">
            Contact
          </RouterLink>
        </li>
        <li className="nav-item">
          <a
            href="https://calendly.com/loic-luttringer/decouvertes-de-mes-services"
            className="reserve-call-btn"
          >
            Réserver un rendez-vous
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
