import React from 'react';
import './ThankYou.css'; // Assurez-vous de créer également ce fichier CSS

function ThankYou() {
  return (
    <div className="thank-you-container">
      <h1 className="thank-you-title">Merci pour votre réservation !</h1>
      <p className="thank-you-message">
        Nous vous remercions d'avoir réservé un créneau avec notre agence via Calendly. Nous avons hâte de discuter avec vous et de vous aider à atteindre vos objectifs en ligne.
      </p>
      <a href="/" className="thank-you-button">Retour à l'accueil</a>
    </div>
  );
}

export default ThankYou;
