import React from "react";
import "./Informatix.css";
import Image1 from "../assets/img/informatixP/screen1.PNG";
import Image2 from "../assets/img/informatixP/screen2.PNG";
import Image3 from "../assets/img/informatixP/screen3.PNG";

const InformatixPage = () => {
  return (
    <div className="informatix-container">
      <h1 className="informatix-title">Informatix</h1>
      <div className="content">
        <img src={Image1} alt="acceuil site" className="main-image" />
        <p className="lorem-text">
          Découvrez notre{" "}
          <span className="highlight">plateforme éducative innovante</span> sur
          la page d'accueil, conçue pour offrir une{" "}
          <span className="highlight">
            expérience utilisateur intuitive et enrichissante
          </span>
          . Avec son interface épurée et ses visuels accrocheurs, notre page
          d'accueil guide les utilisateurs vers nos programmes et cours de
          manière efficace. Elle met en avant la{" "}
          <span className="highlight">
            facilité d'accès aux ressources éducatives
          </span>
          , tout en présentant notre approche pédagogique unique.
        </p>
      </div>
      <div className="content">
        <img src={Image2} alt="sommaire cours" className="main-image" />
        <p className="lorem-text">
          La page de sommaire de notre site offre une{" "}
          <span className="highlight">vue d'ensemble structurée</span> de nos
          offres éducatives, facilitant aux apprenants la navigation et la
          sélection de cours. Chaque programme est clairement répertorié avec
          <span className="highlight"> des aperçus détaillés</span>, permettant
          une compréhension rapide des sujets et{" "}
          <span className="highlight">objectifs d'apprentissage</span>. Cette
          page est conçue pour aider les utilisateurs à planifier efficacement
          leur parcours éducatif
        </p>
      </div>
      <div className="content">
        <img src={Image3} alt="exemple cours" className="main-image" />
        <p className="lorem-text">
          Notre contenu de cours exemplaire illustre parfaitement notre{" "}
          <span className="highlight">
            engagement à fournir une expérience d'apprentissage complète et
            immersive
          </span>
          . Les leçons intègrent des supports multimédias, des cours théoriques
          Cette section démontre comment nous combinons théorie et les exemple
          pour offrir un enseignement{" "}
          <span className="highlight">dynamique et pertinent.</span>
        </p>
      </div>
    </div>
  );
};

export default InformatixPage;
