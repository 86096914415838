import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy-container">
      <h1 className="privacy-title">Politique de Confidentialité</h1>
      <p className="privacy-intro">
        Chez Bloomy, nous respectons votre vie privée. Cette politique de confidentialité
        explique comment nous utilisons les informations dans notre application, notamment
        en ce qui concerne les publicités via Google AdMob.
      </p>

      <section className="privacy-section">
        <h2>1. Absence de Collecte de Données Personnelles</h2>
        <p>
          Bloomy ne recueille aucune donnée personnelle de ses utilisateurs. Nous ne collectons,
          ne stockons, ni ne partageons d'informations permettant de vous identifier personnellement.
        </p>
      </section>

      <section className="privacy-section">
        <h2>2. Publicités via Google AdMob</h2>
        <p>
          Notre application affiche des publicités fournies par Google AdMob. Ces publicités peuvent
          être personnalisées en fonction de vos intérêts, mais cette personnalisation est gérée par
          Google, et nous n'avons accès à aucune donnée utilisée pour ce processus.
        </p>
        <p>
          Google AdMob peut utiliser des identifiants publicitaires anonymes pour personnaliser
          les publicités que vous voyez. Pour en savoir plus sur la manière dont Google utilise
          les données lorsque vous utilisez nos partenaires' sites ou applications, vous pouvez
          consulter les <a href="https://policies.google.com/technologies/ads" className="privacy-link" target="_blank" rel="noopener noreferrer">règles de confidentialité de Google</a>.
        </p>
      </section>

      <section className="privacy-section">
        <h2>3. Sécurité</h2>
        <p>
          Bien que nous ne recueillions aucune donnée personnelle, nous prenons des mesures pour
          assurer que toutes les informations traitées dans l'application sont protégées.
        </p>
      </section>

      <section className="privacy-section">
        <h2>4. Vos Droits</h2>
        <p>
          Si vous avez des questions concernant les publicités ou toute autre fonctionnalité de l'application,
          vous pouvez nous contacter via notre <a href="/contact" className="privacy-link">formulaire de contact</a>.
        </p>
      </section>

      <section className="privacy-section">
        <h2>5. Modifications de cette Politique</h2>
        <p>
          Cette politique de confidentialité peut être mise à jour de temps en temps. Nous vous
          informerons de tout changement en publiant la nouvelle politique sur cette page.
          Nous vous encourageons à consulter régulièrement cette page pour rester informé.
        </p>
      </section>

      <section className="privacy-section">
        <h2>6. Contact</h2>
        <p>
          Si vous avez des questions concernant cette politique de confidentialité, vous pouvez
          nous contacter via notre <a href="/contact" className="privacy-link">formulaire de contact</a>.
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
