import React, { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './ToDo.css';

const ToDo = ({ tasks = [], setTasks, onCreateTask }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setTitle('');
    setDescription('');
  };

  const handleSave = () => {
    if (title.trim() && description.trim()) {
      const newTask = { title, description, status: 'pending' };
      onCreateTask(newTask);
      handleCloseModal();
    } else {
      alert("Titre et description ne peuvent pas être vides");
    }
  };

  const handleStatusChange = (id, newStatus) => {
    setTasks(prevTasks => prevTasks.map(task => task.id === id ? { ...task, status: newStatus } : task));
  };

  const handleDelete = (id) => {
    setTasks(prevTasks => prevTasks.filter(task => task.id !== id));
  };

  const filteredTasks = (status) => tasks.filter(task => task.status === status);

  const location = useLocation();
  const isTasksPage = location.pathname === '/crm/to-do/tasks';

  return (
    <div className="todo-page">
      <div className="header">
        <h1>To-Do List</h1>
        {(location.pathname === '/crm/to-do' || isTasksPage) && (
          <button className="add-button" onClick={handleOpenModal}>Ajouter</button>
        )}
      </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Ajouter une tâche</h2>
            <input
              type="text"
              placeholder="Titre"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="modal-buttons">
              <button onClick={handleSave}>Enregistrer</button>
              <button onClick={handleCloseModal}>Annuler</button>
            </div>
          </div>
        </div>
      )}
      {location.pathname === '/crm/to-do' && (
        <>
          <div className="task-category">
            <h2>Tâches à faire <span className="chevron">&gt;</span></h2>
            <div className="tasks-list">
              {filteredTasks('pending').length === 0 ? (
                <p className="no-tasks">Aucune tâche à faire</p>
              ) : (
                filteredTasks('pending').map((task) => (
                  <div key={task.id} className="task-card">
                    <div className="status-buttons">
                      <button
                        className="status-button"
                        onClick={() => handleStatusChange(task.id, 'in-progress')}
                        title="Marquer comme en cours"
                      >
                        <span className="status-icon">&#x23F3;</span> {/* Icône de sablier */}
                      </button>
                      <button
                        className="delete-button"
                        onClick={() => handleDelete(task.id)}
                        title="Supprimer la tâche"
                      >
                        &#x2716; {/* Icône de croix */}
                      </button>
                    </div>
                    <div className="task-content">
                      <h3>{task.title}</h3>
                      <p>{task.description}</p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="task-category">
            <h2>Tâches en cours <span className="chevron">&gt;</span></h2>
            <div className="tasks-list">
              {filteredTasks('in-progress').length === 0 ? (
                <p className="no-tasks">Aucune tâche en cours</p>
              ) : (
                filteredTasks('in-progress').map((task) => (
                  <div key={task.id} className="task-card">
                    <div className="status-buttons">
                      <button
                        className="status-button-square"
                        onClick={() => handleStatusChange(task.id, 'completed')}
                        title="Marquer comme terminé"
                      >
                        <span className="status-icon">&#x2713;</span> {/* Icône de check */}
                      </button>
                      <button
                        className="delete-button"
                        onClick={() => handleDelete(task.id)}
                        title="Supprimer la tâche"
                      >
                        &#x2716; {/* Icône de croix */}
                      </button>
                    </div>
                    <div className="task-content">
                      <h3>{task.title}</h3>
                      <p>{task.description}</p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="task-category">
            <h2>Tâches effectuées <span className="chevron">&gt;</span></h2>
            <div className="tasks-list">
              {filteredTasks('completed').length === 0 ? (
                <p className="no-tasks">Aucune tâche effectuée</p>
              ) : (
                filteredTasks('completed').map((task) => (
                  <div key={task.id} className="task-card">
                    <div className="status-buttons">
                      <button
                        className="delete-button"
                        onClick={() => handleDelete(task.id)}
                        title="Supprimer la tâche"
                      >
                        &#x2716; {/* Icône de croix */}
                      </button>
                    </div>
                    <div className="task-content">
                      <h3>{task.title}</h3>
                      <p>{task.description}</p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </>
      )}
      <Routes>
        <Route path="tasks" element={<TaskList title="Tâches à faire" tasks={filteredTasks('pending')} onStatusChange={handleStatusChange} onDelete={handleDelete} />} />
        <Route path="in-progress" element={<TaskList title="Tâches en cours" tasks={filteredTasks('in-progress')} onStatusChange={handleStatusChange} onDelete={handleDelete} />} />
        <Route path="completed" element={<TaskList title="Tâches effectuées" tasks={filteredTasks('completed')} onDelete={handleDelete} />} />
      </Routes>
    </div>
  );
};

const TaskList = ({ title, tasks, onStatusChange, onDelete }) => (
  <div className="task-list-page">
    <h2>{title}</h2>
    <div className="tasks-list">
      {tasks.length === 0 ? (
        <p className="no-tasks">Aucune tâche à afficher</p>
      ) : (
        tasks.map((task) => (
          <div key={task.id} className="task-card">
            <div className="status-buttons">
              {onStatusChange && (
                <button
                  className="status-button"
                  onClick={() => onStatusChange(task.id, 'in-progress')}
                  title="Marquer comme en cours"
                >
                  <span className="status-icon">&#x23F3;</span> {/* Icône de sablier */}
                </button>
              )}
              {onStatusChange && (
                <button
                  className="status-button-square"
                  onClick={() => onStatusChange(task.id, 'completed')}
                  title="Marquer comme terminé"
                >
                  <span className="status-icon">&#x2713;</span> {/* Icône de check */}
                </button>
              )}
              <button
                className="delete-button"
                onClick={() => onDelete(task.id)}
                title="Supprimer la tâche"
              >
                &#x2716; {/* Icône de croix */}
              </button>
            </div>
            <div className="task-content">
              <h3>{task.title}</h3>
              <p>{task.description}</p>
            </div>
          </div>
        ))
      )}
    </div>
  </div>
);

export default ToDo;
