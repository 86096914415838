import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faPlus, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import './CrmProjectsPage.css';

const CrmProjectsPage = ({ projects }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleDelete = (projectId) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce projet?')) {
      // Ajoutez ici la logique pour supprimer le projet
    }
  };

  return (
    <div className="crm-projects-page">
      <h2>CRM Projects</h2>
      <div className="buttons-container">
        <button className="add-button">
          <FontAwesomeIcon icon={faPlus} /> Ajouter
        </button>
        <button className="edit-button" onClick={() => setIsEditMode(!isEditMode)}>
          <FontAwesomeIcon icon={faEdit} /> Modifier
        </button>
      </div>
      <div className="projects-list">
        {projects.length === 0 ? (
          <p>Aucun projet trouvé</p>
        ) : (
          projects.map((project) => (
            <div key={project.id} className="folder">
              {isEditMode && (
                <button className="delete-button" onClick={() => handleDelete(project.id)}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              )}
              <div className="folder-content">
                <FontAwesomeIcon icon={faFolder} size="3x" />
                <h3>{project.name}</h3>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CrmProjectsPage;
