import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import './Documents.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faPlus, faEdit, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';

const Documents = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState(() => {
    const savedDocuments = localStorage.getItem('documents');
    return savedDocuments ? JSON.parse(savedDocuments) : {
      clients: [],
      prospects: [],
      comptabiliteInterne: []
    };
  });
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    localStorage.setItem('documents', JSON.stringify(documents));
  }, [documents]);

  const categories = [
    { name: 'Clients', icon: faFolder, path: 'clients' },
    { name: 'Prospects', icon: faFolder, path: 'prospects' },
    { name: 'Comptabilité Interne', icon: faFolder, path: 'comptabiliteInterne' }
  ];

  const handleCategoryClick = (path) => {
    navigate(`/crm/documents/${path}`);
  };

  const handleUpload = (category, event) => {
    const files = Array.from(event.target.files).map(file => ({
      name: file.name,
      url: URL.createObjectURL(file),
      file
    }));
    const updatedDocuments = { ...documents };
    if (!updatedDocuments[category]) {
      updatedDocuments[category] = [];
    }
    updatedDocuments[category].push({ name: category, files });
    setDocuments(updatedDocuments);
    console.log('Documents after upload:', updatedDocuments);
  };

  const handleFolderUpload = (folderName, category, event) => {
    const files = Array.from(event.target.files).map(file => ({
      name: file.name,
      url: URL.createObjectURL(file),
      file
    }));
    const updatedDocuments = { ...documents };
    const categoryKey = category.toLowerCase().replace(/\s+/g, '');
    if (!updatedDocuments[categoryKey]) {
      updatedDocuments[categoryKey] = [];
    }
    const folderIndex = updatedDocuments[categoryKey].findIndex(folder => folder.name === folderName);
    if (folderIndex !== -1) {
      updatedDocuments[categoryKey][folderIndex].files.push(...files);
    } else {
      updatedDocuments[categoryKey].push({ name: folderName, files });
    }
    setDocuments(updatedDocuments);
    console.log('Documents after folder upload:', updatedDocuments);
  };

  const handleDelete = (category, docToDelete) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce document?')) {
      const updatedDocuments = { ...documents };
      const categoryKey = category.toLowerCase().replace(/\s+/g, '');
      updatedDocuments[categoryKey] = updatedDocuments[categoryKey].filter(doc => doc.url !== docToDelete.url);
      setDocuments(updatedDocuments);
    }
  };

  const handleFolderDelete = (category, folderToDelete) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce dossier?')) {
      const updatedDocuments = { ...documents };
      const categoryKey = category.toLowerCase().replace(/\s+/g, '');
      updatedDocuments[categoryKey] = updatedDocuments[categoryKey].filter(folder => folder.name !== folderToDelete);
      setDocuments(updatedDocuments);
    }
  };

  return (
    <div className="documents-page">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <h1>Documents</h1>
              <div className="categories">
                {categories.map((category) => (
                  <div key={category.name} className="category" onClick={() => handleCategoryClick(category.path)}>
                    <FontAwesomeIcon icon={category.icon} size="3x" />
                    <span>{category.name}</span>
                  </div>
                ))}
              </div>
            </>
          }
        />
        {categories.map(({ name, path }) => (
          <Route
            key={path}
            path={path}
            element={
              <DocumentList
                title={name}
                documents={documents[path.toLowerCase().replace(/\s+/g, '')] || []}
                onUpload={handleUpload}
                onDelete={handleDelete}
                onFolderDelete={handleFolderDelete}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                navigate={navigate}
              />
            }
          />
        ))}
        {categories.map(({ path }) => (
          <Route
            key={path}
            path={`${path}/:folderName`}
            element={
              <FolderView
                documents={documents[path.toLowerCase().replace(/\s+/g, '')] || []}
                onUpload={handleFolderUpload}
                onDelete={handleDelete}
                isEditMode={isEditMode}
              />
            }
          />
        ))}
      </Routes>
    </div>
  );
};

const DocumentList = ({ title, documents, onUpload, onDelete, onFolderDelete, isEditMode, setIsEditMode, navigate }) => {
  return (
    <div className="document-list-page">
      <h2 className="category-title">{title}</h2>
      <div className="buttons-container">
        <input
          type="file"
          multiple
          id={`file-upload-${title}`}
          style={{ display: 'none' }}
          onChange={(event) => onUpload(title.toLowerCase().replace(/\s+/g, ''), event)}
        />
        <button className="add-button" onClick={() => document.getElementById(`file-upload-${title}`).click()}>
          <FontAwesomeIcon icon={faPlus} /> Ajouter
        </button>
        <button className="edit-button" onClick={() => setIsEditMode(!isEditMode)}>
          <FontAwesomeIcon icon={faEdit} /> Modifier
        </button>
      </div>
      <div className="documents-list">
        {documents.length === 0 ? (
          <p>Aucun document trouvé</p>
        ) : (
          documents.map((folder, index) => (
            <div key={index} className="folder">
              <div className="folder-header">
                <FontAwesomeIcon icon={faFolder} size="3x" onClick={() => navigate(`${title.toLowerCase().replace(/\s+/g, '')}/${folder.name}`)} />
                <h3 onClick={() => navigate(`${title.toLowerCase().replace(/\s+/g, '')}/${folder.name}`)}>{folder.name}</h3>
                {isEditMode && (
                  <button className="delete-button" onClick={() => onFolderDelete(title.toLowerCase().replace(/\s+/g, ''), folder.name)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const FolderView = ({ documents, onUpload, onDelete, isEditMode }) => {
  const { folderName } = useParams();
  const folder = documents.find(folder => folder.name === folderName);

  return (
    <div className="folder-view-page">
      <h2 className="folder-title">{folderName}</h2>
      {folder && (
        <div className="buttons-container">
          <input
            type="file"
            multiple
            id={`file-upload-${folderName}`}
            style={{ display: 'none' }}
            onChange={(event) => onUpload(folderName, 'clients', event)}
          />
          <button className="add-button" onClick={() => document.getElementById(`file-upload-${folderName}`).click()}>
            <FontAwesomeIcon icon={faPlus} /> Ajouter
          </button>
        </div>
      )}
      {folder ? (
        <div className="documents-list">
          {folder.files.length === 0 ? (
            <p>Aucun document trouvé</p>
          ) : (
            folder.files.map((doc, idx) => (
              <div key={idx} className="document-card">
                <span>{doc.name}</span>
                <div className="document-actions">
                  <a href={doc.url} download={doc.name}>
                    <FontAwesomeIcon icon={faDownload} /> Télécharger
                  </a>
                  {isEditMode && (
                    <button className="delete-button" onClick={() => onDelete(folder.name, doc)}>×</button>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      ) : (
        <p>Dossier non trouvé</p>
      )}
    </div>
  );
};

export default Documents;
