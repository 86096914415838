import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isToDoSubmenuOpen, setIsToDoSubmenuOpen] = useState(false);
  const [isDocumentsSubmenuOpen, setIsDocumentsSubmenuOpen] = useState(false);
  const [isCrmProjectsSubmenuOpen, setIsCrmProjectsSubmenuOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    if (location.pathname.startsWith("/crm/to-do")) {
      setIsToDoSubmenuOpen(true);
      setSelectedCategory(location.pathname);
    } else {
      setIsToDoSubmenuOpen(false);
    }
    if (location.pathname.startsWith("/crm/documents")) {
      setIsDocumentsSubmenuOpen(true);
      setSelectedCategory(location.pathname);
    } else {
      setIsDocumentsSubmenuOpen(false);
    }
    if (location.pathname.startsWith("/crm/crm-projects")) {
      setIsCrmProjectsSubmenuOpen(true);
      setSelectedCategory(location.pathname);
    } else {
      setIsCrmProjectsSubmenuOpen(false);
    }
  }, [location.pathname]);

  const handleToDoClick = () => {
    setIsToDoSubmenuOpen(!isToDoSubmenuOpen);
    setSelectedCategory("to-do");
    navigate("/crm/to-do");
  };

  const handleDocumentsClick = () => {
    setIsDocumentsSubmenuOpen(!isDocumentsSubmenuOpen);
    setSelectedCategory("documents");
    navigate("/crm/documents");
  };

  const handleCrmProjectsClick = () => {
    setIsCrmProjectsSubmenuOpen(!isCrmProjectsSubmenuOpen);
    setSelectedCategory("crm-projects");
    navigate("/crm/crm-projects");
  };

  const handleSubCategoryClick = (path) => {
    setSelectedCategory(path);
    navigate(path);
  };

  return (
    <aside className="sidebar">
      <ul>
        <li className={location.pathname === "/crm/dashboard" ? "active" : ""}>
          <Link to="/crm/dashboard" className="sidebar-link">Dashboard</Link>
        </li>
        <li className={location.pathname === "/crm/statistics" ? "active" : ""}>
          <Link to="/crm/statistics" className="sidebar-link">Statistics</Link>
        </li>
        <li className={location.pathname === "/crm/notebook" ? "active" : ""}>
          <Link to="/crm/notebook" className="sidebar-link">Notebook</Link>
        </li>
        <li className={selectedCategory === "to-do" || location.pathname.startsWith("/crm/to-do") ? "active" : ""}>
          <span className="sidebar-link" onClick={handleToDoClick}>To-Do</span>
          {isToDoSubmenuOpen && (
            <ul className="submenu">
              <li className={selectedCategory === "/crm/to-do/tasks" ? "active-sub" : ""}>
                <span className="sidebar-link" onClick={() => handleSubCategoryClick("/crm/to-do/tasks")}>Tâches à faire</span>
              </li>
              <li className={selectedCategory === "/crm/to-do/in-progress" ? "active-sub" : ""}>
                <span className="sidebar-link" onClick={() => handleSubCategoryClick("/crm/to-do/in-progress")}>Tâches en cours</span>
              </li>
              <li className={selectedCategory === "/crm/to-do/completed" ? "active-sub" : ""}>
                <span className="sidebar-link" onClick={() => handleSubCategoryClick("/crm/to-do/completed")}>Tâches effectuées</span>
              </li>
            </ul>
          )}
        </li>
        <li className={selectedCategory === "crm-projects" || location.pathname.startsWith("/crm/crm-projects") ? "active" : ""}>
          <span className="sidebar-link" onClick={handleCrmProjectsClick}>CRM Projects</span>
          
        </li>
        <li className={location.pathname === "/crm/client-space" ? "active" : ""}>
          <Link to="/crm/client-space" className="sidebar-link">Client Space</Link>
        </li>
        <li className={selectedCategory === "documents" || location.pathname.startsWith("/crm/documents") ? "active" : ""}>
          <span className="sidebar-link" onClick={handleDocumentsClick}>Documents</span>
          {isDocumentsSubmenuOpen && (
            <ul className="submenu">
              <li className={selectedCategory === "/crm/documents/clients" ? "active-sub" : ""}>
                <span className="sidebar-link" onClick={() => handleSubCategoryClick("/crm/documents/clients")}>Clients</span>
              </li>
              <li className={selectedCategory === "/crm/documents/prospects" ? "active-sub" : ""}>
                <span className="sidebar-link" onClick={() => handleSubCategoryClick("/crm/documents/prospects")}>Prospects</span>
              </li>
              <li className={selectedCategory === "/crm/documents/comptabilite-interne" ? "active-sub" : ""}>
                <span className="sidebar-link" onClick={() => handleSubCategoryClick("/crm/documents/comptabilite-interne")}>Comptabilité Interne</span>
              </li>
            </ul>
          )}
        </li>
        <li className={location.pathname === "/crm/contacts" ? "active" : ""}>
          <Link to="/crm/contacts" className="sidebar-link">Contacts</Link>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
